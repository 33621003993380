import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { Address, Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useCart, useCountriesFromCache } from '$src/hooks/queries';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { gateway, setXCountryGatewayHeader } from '$src/http';

export const UPDATE_SHIPPING_ADDRESS_MUTATION_KEY = 'MUTATION/UPDATE_SHIPPING_ADDRESS';

export const useUpdateShippingAddress = () => {
  const { initCorrections } = useCorrectionsAtom();
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const countries = useCountriesFromCache();
  const { data: cart } = useCart();
  const updateCartId = useCartIdUpdater();

  const mutation = useMutation({
    mutationFn: ({ address, email }: { address: Address; email: string }) => {
      const countryCodeContext =
        (address?.countryCode && address?.countryCode.length !== 0 ? address?.countryCode : undefined) ??
        countries.find((country) => country.country === address?.country)?.value;
      return gateway.post<Cart>(`${baseUrl}/update-shipping-address`, { address, email, countryCodeContext });
    },
    mutationKey: [UPDATE_SHIPPING_ADDRESS_MUTATION_KEY, baseUrl],
    onSuccess: ({ data }) => {
      updateCartId(data?.id);
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
      if (data.corrections?.length) {
        initCorrections(data.corrections);
      }
      if (data.shipping.address?.countryCode) {
        setXCountryGatewayHeader(data.shipping.address?.countryCode?.toLocaleUpperCase());
      }
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        if (error?.response?.data?.error?.[0]?.code === 'NOT_SUPPORTED') {
          const cartWithoutShippingMethod: Cart = {
            ...(cart as Cart),
            shipping: {
              ...cart?.shipping,
              // @ts-expect-error - address1 is set for triggering unavailable message in components/Delivery/commons/ShippingMethods.tsx
              address: {
                address1: 'unavailable shipping',
              },
              ready: Boolean(cart?.shipping.ready),
              methods: [],
              selected: undefined,
            },
          };
          queryClient.setQueryData<Cart>(
            [CART_QUERY_KEY, cartWithoutShippingMethod.id],
            cartWithoutShippingMethod,
          );
        }
      }
    },
  });

  return { updateShippingAddress: mutation.mutateAsync, state: mutation.status, reset: mutation.reset };
};
