import { Oops } from '@getjust/oops';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { USER_LOCATION_DETECTION_QUERY_KEY } from '$src/constants';
import { capturePosthogAnalytics } from '$src/utils';
import { withTimeout } from '$src/utils/timeout';

export type LocationResponse = {
  location: {
    latitude: number;
    longitude: number;
    country: {
      code: string;
    };
  };
};

export async function getUserLocation(timeoutMillis = 500): Promise<LocationResponse> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const API_KEY = process.env.NEXT_PUBLIC_LOCATION_API_KEY!;
    const responsePromise = axios.get<LocationResponse>(
      `https://api.ipregistry.co/?key=${API_KEY}&fields=locations,location.longitude,location.latitude,location.country.code&hostname=true&pretty=true`,
    );

    const response = await withTimeout(responsePromise, timeoutMillis);

    if (response === 'timeout') {
      throw new Error('Location request timed out');
    }

    capturePosthogAnalytics('location_found', {
      location: `${response.data.location.latitude},${response.data.location.longitude}`,
    });

    if (response.data.location.latitude === 0 && response.data.location.longitude === 0) {
      throw new Oops('Invalid latitude & longitude given by ipregistry');
    }
    return response.data;
  } catch {
    return {
      location: {
        country: { code: 'FR' },
        latitude: 50.69308,
        longitude: 3.18456,
      },
    };
  }
}

export const useUserLocationDetection = (timeoutMillis?: number) => {
  return useQuery({
    queryKey: [USER_LOCATION_DETECTION_QUERY_KEY],
    queryFn: () => getUserLocation(timeoutMillis),
  });
};
