import { Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';

import { useShop, useUserInfo } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';

export interface KeyValue {
  [key: string]: string | number | undefined | string[] | number[];
}

export const addIdentify = (name: string, value: string) => {
  const identifyEvent = new Identify();
  identifyEvent.set(name, value);
  identify(identifyEvent);
};

export const useTrackAmplitude = () => {
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { data: currentUser } = useUserInfo();
  const [eventQueue, setEventQueue] = useState<Array<{ name: string; props?: KeyValue }>>([]);
  const [eventCounts, setEventCounts] = useState<Record<string, number>>({});

  const setQueueEvent = useCallback(
    (event: { name: string; props?: KeyValue }) => {
      const eventIdentifier = `${event.name}-${JSON.stringify(event.props)}`;
      const isEventAlreadyQueued = eventQueue.some(
        (queuedEvent) =>
          queuedEvent.name === event.name &&
          JSON.stringify(queuedEvent.props) === JSON.stringify(event.props),
      );
      setEventCounts((prevCounts) => {
        const currentCount = prevCounts[eventIdentifier] || 0;
        if (currentCount > 2) {
          return prevCounts;
        }
        const updatedCounts = { ...prevCounts, [eventIdentifier]: currentCount + 1 };
        if (currentCount <= 2 && !isEventAlreadyQueued) {
          setEventQueue((prevQueue) => [...prevQueue, event]);
        }
        return updatedCounts;
      });
    },
    [eventQueue],
  );

  useEffect(() => {
    if (!shop?.features.includes('analytics_amplitude') || !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      return;
    }
    if (currentUser?.id) {
      setUserId(currentUser?.id);
    }
  }, [currentUser?.id, shop?.features]);

  const trackEvent = useCallback(
    (name: string, props?: KeyValue) => {
      if (!shop?.features.includes('analytics_amplitude') || !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
        console.error(
          'Tracking failure with Amplitude due to shop info retrieval issues or missing API key for event:',
          name,
        );
        setQueueEvent({ name, props });
        return;
      }
      try {
        return track(name, {
          ...props,
          shop: shop?.name,
          language: message?.language,
          shopId: message?.shopId,
          cartId: message?.cart.id,
          platform: shop?.platformType,
          source:
            message?.source === 'product-form'
              ? 'product'
              : message?.source === 'customV2'
                ? 'custom'
                : message?.source,
          posthogSessionId:
            'https://us.posthog.com/replay/' +
            posthog?.sessionManager?.checkAndGetSessionAndWindowId().sessionId,
        });
      } catch (error) {
        console.error('Failed to track with Amplitude', { error });
        setQueueEvent({ name, props });
        return;
      }
    },
    [
      message?.cart.id,
      message?.language,
      message?.shopId,
      message?.source,
      shop?.features,
      shop?.name,
      shop?.platformType,
      setQueueEvent,
    ],
  );

  useEffect(() => {
    if (
      shop?.features.includes('analytics_amplitude') &&
      process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY &&
      eventQueue.length > 0
    ) {
      eventQueue.forEach((event) => {
        trackEvent(event.name, event.props);
      });
      setEventQueue([]);
    }
  }, [shop?.features, eventQueue, trackEvent]);

  return {
    track: trackEvent,
  };
};
