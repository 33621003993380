import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Divider, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { formatPrice } from '@getjust/commons';
import { JustSkeleton } from '@getjust/leaf';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';

import IconWithBadge from '$components/IconWithBadge';
import ShippingLogo from '$components/SvgLogos/ShippingLogo';
import { useCart, useShop } from '$hooks/queries';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useEstimatedShippingAtom } from '$src/hooks/state/useEstimatedShippingAtom';
import { useRecommendedDiscountsAtom } from '$src/hooks/state/useRecommendedDiscountsAtom';
import { useShowCartAtom } from '$src/hooks/state/useShowCartAtom';

export const CartSummary = () => {
  const { isMobile } = useIsDesktop();
  const { data: shop } = useShop();
  const { data: cart } = useCart();

  const { toggle } = useShowCartAtom();
  const { t } = useTranslation(['common']);
  const { track } = useTrackAmplitude();
  const { estimatedShipping } = useEstimatedShippingAtom();
  const { recommendedDiscountsAtom: recommendedDiscount } = useRecommendedDiscountsAtom();

  // NOTE: Discount code / title should fit in one line
  const { discountTitleFontSize, discountAddLogoSize, discountAppliedLogoSize } = useMemo(() => {
    const sizeLength =
      (cart?.discounts
        ? cart.discounts.length === 0
          ? `${t('commons.discount_code').toUpperCase()}`.length
          : `${t('commons.discount_applied').toUpperCase()}`.length
        : t('notAuthed.promoCode').length) ?? 0;
    const condition = sizeLength > 10 && window.innerWidth < 340;
    const discountTitleFontSize = condition ? '3xs' : '13px';
    const discountAddLogoSize = condition ? '0.5rem' : '1rem';
    const discountAppliedLogoSize = condition ? '0.375rem' : '0.75rem';

    return { discountTitleFontSize, discountAddLogoSize, discountAppliedLogoSize };
  }, [cart?.discounts, t]);

  const onClickCartSummary = useCallback(() => {
    track('Cart summary opened', { value: 'Cart summary' });
    toggle();
  }, [toggle, track]);

  const isShippingCalculating = useMemo(
    () => !(estimatedShipping || (cart?.shipping?.selected && cart?.shipping?.address)),
    [estimatedShipping, cart],
  );

  const shippingMessage = useMemo(() => {
    if (
      cart?.shipping?.selected &&
      cart?.shipping?.address &&
      cart?.shipping?.address?.firstName !== 'Just' && // JUST Users in Prestashop
      cart?.shipping?.address?.lastName !== 'Anonymous' && // mandatory to have estimated
      cart?.shipping?.address?.address1 !== '142 AVENUE LEDRU ROLLIN' // shipping in prestashop
    ) {
      return cart?.totalShipping === 0
        ? t('notAuthed.freeShipping')
        : `${t('notAuthed.shipping', {
            amount: formatPrice(cart?.totalShipping, {
              currency: cart?.currency ? cart.currency : 'EUR',
            }),
          })}`;
    }
    if (estimatedShipping) {
      return estimatedShipping.amount === 0
        ? t('notAuthed.freeShipping')
        : `${t('notAuthed.estimatedShipping', {
            amount: formatPrice(estimatedShipping.amount, {
              currency: cart?.currency,
            }),
          })}`;
    }

    return t('commons.calculating');
  }, [cart, t, estimatedShipping]);

  const getDiscountAppliedLabel = useMemo(
    () =>
      cart?.discounts?.length === 1
        ? t('commons.discount_applied', { count: cart?.discounts?.length })
        : t('commons.discount_applied_plurials', { count: cart?.discounts?.length }),
    [cart?.discounts?.length, t],
  );

  const showLoader = useMemo(() => !shop || !cart, [shop, cart]);

  const [srcImage, setSrcImage] = useState(
    cart?.lineItems?.[0]?.imageUrl ? cart.lineItems[0].imageUrl : '/images/cartBlack.svg',
  );
  const onErrorImage = () => {
    setSrcImage('/images/cartBlack.svg');
  };

  return isMobile ? (
    <>
      {showLoader ? (
        <JustSkeleton minHeight="6.25rem" borderRadius="3xl" mx={2} mb={8} />
      ) : (
        <Box bgColor="white" zIndex={5} mb="8">
          <VStack
            cursor="pointer"
            onClick={onClickCartSummary}
            px={4}
            py={3}
            flexDirection="column"
            alignItems="flex-start"
            bg="#FFF"
            borderRadius="3xl"
            mx={2}
            mb={2}
            boxShadow="2px 2px 10px 0px rgba(0, 0, 0, 0.05), -2px -2px 12px 0px rgba(0, 0, 0, 0.05)"
            spacing={1}
          >
            <HStack alignItems="center" gap="3">
              {srcImage === '/images/cartBlack.svg' ? (
                <Box
                  width="2.25rem"
                  height="2.25rem"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  flexShrink="0"
                  borderRadius="3xl"
                  border="1px solid rgba(0, 0, 0, 0.02)"
                >
                  <Box boxSize="1.5rem" alignContent="center" alignItems="center" alignSelf="center">
                    <Image src="/images/cartBlack.svg" alt="cart icon" ml="1" />
                  </Box>
                </Box>
              ) : (
                <IconWithBadge
                  imageUrl={srcImage}
                  badgeText={cart?.lineItems?.reduce((prev, next) => prev + next.quantity, 0) ?? 0}
                  alt="cart icon"
                  onError={onErrorImage}
                  withBackground={srcImage === '/images/cartBlack.svg'}
                />
              )}

              <HStack alignItems="center" spacing={2}>
                {cart?.discounts?.length ? (
                  <>
                    <HStack
                      py={1}
                      px={1.5}
                      bg="linear-gradient(90deg, #8DE690 2.08%, #A5F0A3 18.23%, #A6ECD7 33.33%, #97DADA 51.56%, #B3DAE7 67.71%, #B6C9EC 83.33%, #879ACB 100%)"
                      borderRadius="0.25rem"
                      spacing={1}
                    >
                      <Image
                        alt="discount applied"
                        src="/images/applied.svg"
                        boxSize={discountAppliedLogoSize}
                      />
                      {cart?.discounts && (
                        <Text color="#1C1C1C" fontWeight="medium" fontSize={discountTitleFontSize}>
                          {getDiscountAppliedLabel}
                        </Text>
                      )}
                    </HStack>
                    <Divider
                      orientation="vertical"
                      width="0.0625rem"
                      height="0.75rem"
                      background="#8F8F8F"
                      backgroundColor="#8F8F8F"
                      borderRadius={1}
                    />
                  </>
                ) : (
                  <>
                    <HStack
                      py={1}
                      px={1.5}
                      backgroundColor={recommendedDiscount?.length ? '#F6BCFF' : '#E0E0E0'}
                      borderRadius="0.25rem"
                      spacing={1}
                    >
                      <Image alt="add promo code" src="/images/magnifier.svg" boxSize={discountAddLogoSize} />
                      {recommendedDiscount?.length ? (
                        <Text color="#1C1C1C" fontWeight="medium" fontSize={discountTitleFontSize}>
                          {recommendedDiscount.length > 1
                            ? t('commons.discount_found_plurials', { count: recommendedDiscount.length })
                            : t('commons.discount_found', { count: recommendedDiscount.length })}
                        </Text>
                      ) : (
                        <Text color="#1C1C1C" fontWeight="medium" fontSize={discountTitleFontSize}>
                          {t('commons.discount_code')}
                        </Text>
                      )}
                    </HStack>
                    <Divider
                      orientation="vertical"
                      width="0.0625rem"
                      height="0.75rem"
                      background="#8F8F8F"
                      backgroundColor="#8F8F8F"
                      borderRadius={1}
                    />
                  </>
                )}
                {cart?.totalAmount !== undefined && (
                  <Text color="#1C1C1C" textAlign="center" fontWeight="bold" lineHeight="6">{`${formatPrice(
                    cart?.totalAmount,
                    { currency: cart.currency ?? 'EUR' },
                  )} Total`}</Text>
                )}
              </HStack>
            </HStack>

            {
              <HStack display="flex" justifyContent="space-between" w="100%">
                <HStack alignItems="center" gap="3" w="full">
                  <Box
                    width="2.25rem"
                    height="2.25rem"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    flexShrink="0"
                    borderRadius="3xl"
                    border="1px solid rgba(0, 0, 0, 0.02)"
                  >
                    <Box boxSize="1.5rem" alignContent="center" alignItems="center" alignSelf="center">
                      <ShippingLogo />
                    </Box>
                  </Box>
                  {isShippingCalculating ? (
                    <JustSkeleton height="1.5rem" w="75%" />
                  ) : (
                    <Text color="#1C1C1C" textAlign="left" lineHeight="6" pl={0.5} flex="1">
                      {shippingMessage}
                    </Text>
                  )}
                </HStack>
                <ChevronRightIcon rounded="full" bgColor="gray.602" boxSize="1.5rem" />
              </HStack>
            }
          </VStack>
        </Box>
      )}
    </>
  ) : (
    <>
      <VStack
        pr={4}
        pl="2.5"
        py={3}
        flexDirection="column"
        alignItems="flex-start"
        bg="#FFF"
        borderRadius="3xl"
        mb={6}
        mt={2}
        boxShadow="2px 2px 10px 0px rgba(0, 0, 0, 0.05), -2px -2px 12px 0px rgba(0, 0, 0, 0.05)"
        spacing={1}
      >
        <HStack alignItems="center" gap="3" w="full">
          <Box
            width="2.25rem"
            height="2.25rem"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            flexShrink="0"
            borderRadius="3xl"
            border="1px solid rgba(0, 0, 0, 0.02)"
          >
            <Box boxSize="1.5rem" alignContent="center" alignItems="center" alignSelf="center">
              <ShippingLogo />
            </Box>
          </Box>
          {isShippingCalculating ? (
            <Stack w="full">
              <JustSkeleton height="1.5rem" w="75%" />
            </Stack>
          ) : (
            <Text color="#1C1C1C" textAlign="left" lineHeight="6" pl={0.5} flex="1">
              {shippingMessage}
            </Text>
          )}
        </HStack>
      </VStack>
    </>
  );
};
