import { clearStorage, debounce, getStorage, setStorage } from '@getjust/commons';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { useCart } from '$src/hooks/queries';
import { InputsForm } from '$src/types';

interface DataStored {
  email?: string;
  user?: {
    firstname?: string;
    lastname?: string;
    phone_number?: string;
  };
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    country?: string;
    country_code?: string;
    province?: string;
    zip?: string;
  };
}

const PATHS_REMOVE_LOCALSTORAGE = ['/authenticated'];

export const useSaveTempUser = (control: Control<InputsForm>) => {
  const [isActive, setIsActive] = useState(true);
  const [values, setValues] = useState<DataStored | undefined>(undefined);
  const watchFields = useWatch({
    control,
    name: ['email', 'user', 'address'],
  });

  const router = useRouter();
  const { data: cart } = useCart();

  const clearData = () => {
    clearStorage('localStorage', 'tempUser');
    setIsActive(false);
  };

  useEffect(() => {
    // tempUser is deleted if we navigate to one of PATHS_REMOVE_LOCALSTORAGE
    const handleRouteChange = (url: string) => {
      if (PATHS_REMOVE_LOCALSTORAGE.some((path) => url.includes(path))) {
        clearData();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  // init
  useEffect(() => {
    const startData = getStorage<DataStored>('localStorage', 'tempUser');
    if (!startData) return;
    setValues(startData);
  }, []);

  const saveData = useCallback(
    debounce((watchFields) => {
      setStorage('localStorage', 'tempUser', {
        email: watchFields[0] ? watchFields[0] : values?.email,
        user: watchFields[1],
        address: watchFields[2],
      });
    }, 500),
    [],
  );

  useEffect(() => {
    if (!isActive) return;
    if (cart?.customer?.hasAccount) {
      clearData();
      return;
    }
    if (!watchFields?.[0]) return;
    saveData(watchFields);
  }, [cart?.customer?.hasAccount, isActive, saveData, watchFields]);

  return {
    values,
  };
};
