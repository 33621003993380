import { Badge, Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

type IconWithBadgeProps = {
  imageUrl: string;
  badgeText: string | number;
  alt: string;
  size?: 'sm' | 'md';
  onError?: () => void;
  withBackground?: boolean;
};

const IconWithBadge = ({
  imageUrl,
  badgeText,
  alt,
  size = 'sm',
  onError,
  withBackground,
}: IconWithBadgeProps) => {
  return withBackground ? (
    <Image src={imageUrl} alt={alt} />
  ) : (
    <Box
      position="relative"
      display="inline-block"
      width={size === 'sm' ? '1.5rem' : '2.625rem'}
      height={size === 'sm' ? '1.5rem' : '2.625rem'}
      p={2}
      borderRadius={size === 'sm' ? '2px' : '6px'}
      bgColor="#f7f6f4"
    >
      <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
        <Image src={imageUrl} alt={alt} flexShrink={0} onError={onError} />
      </Flex>
      <Badge
        position="absolute"
        top="-0.38rem"
        right="-0.38rem"
        width="1.3rem"
        height="1.3rem"
        borderRadius="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="2px solid #FFF"
        zIndex={1000}
        bgGradient="linear-gradient(90deg, #8DE690 2.08%, #A5F0A3 18.23%, #A6ECD7 33.33%, #97DADA 51.56%, #B3DAE7 67.71%, #B6C9EC 83.33%, #879ACB 100%)"
      >
        <Text
          color="#050505"
          textAlign="center"
          fontFamily="IBM Plex Mono"
          fontSize="0.6875rem"
          fontStyle="normal"
          fontWeight="bold"
          lineHeight="3"
        >
          {badgeText}
        </Text>
      </Badge>
    </Box>
  );
};

export default IconWithBadge;
