import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'next-i18next';

import StarLoadingAnimation from '$animations/starLoading.json';
import { useShop } from '$src/hooks/queries';

export const FormLoader = () => {
  const { t, i18n } = useTranslation();
  const { data: shop } = useShop();

  return (
    <Box
      position="relative"
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="100%"
      display="flex"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      alignSelf="center"
      zIndex="10000"
    >
      <Stack
        w="full"
        display="flex"
        position="relative"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
      >
        <Player src={StarLoadingAnimation} autoplay loop speed={1} style={{ height: '12.5rem' }}>
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>

        <Box w="full" position="fixed" justifyContent="center" alignItems="center" mt="12rem">
          <HStack
            as="div"
            animation="fadeInOut 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <Image src="/images/speed.svg" alt="speed icon" boxSize="1.25rem" />
            <Text textAlign="center">{t('loader.checkoutFaster')}</Text>
          </HStack>
          <HStack
            as="div"
            animation="fadeInOut2 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <Image src="/images/account.svg" alt="account icon" boxSize="1.25rem" />
            <Text textAlign="center">{t('loader.allInOne')}</Text>
          </HStack>
          <HStack
            as="div"
            animation="fadeInOut3 7800ms infinite"
            justifyContent="center"
            alignItems="center"
            spacing="1"
          >
            <Image src="/images/lock.svg" alt="lock icon" boxSize="1.25rem" />
            <Text textAlign="center">{t('loader.safe')}</Text>
          </HStack>
        </Box>
      </Stack>

      {shop?.name !== undefined && (
        <HStack spacing={1} pb={3.5} position="absolute" bottom="0">
          <a
            href={
              i18n.language === 'fr'
                ? 'https://www.getjust.eu/fr/consommateurs'
                : 'https://www.getjust.eu/shoppers'
            }
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
          >
            <Image alt="JUST" src="/images/smallJust.svg" />
          </a>
          <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350" lineHeight="md">
            {t('loader.authorizedBy') + ' ' + shop.name}
          </Text>
        </HStack>
      )}
      <Stack spacing={4}></Stack>
    </Box>
  );
};
